import { Component, OnInit } from '@angular/core';

interface feature {
  icon: string;
  title: string;
};

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  /**
   * Clients Logo
   */
   featuresdata: feature[] = [
    {
      icon: "monitor",
      title: "Application development"
    },
    {
      icon: "heart",
      title: "Design and architecture"
    },
    {
      icon: "eye",
      title: "Data management"
    },
    {
      icon: "bold",
      title: "IT consultancy"
    },
    {
      icon: "feather",
      title: "Cloud management"
    },
    {
      icon: "code",
      title: "Devops"
    },
    {
      icon: "user-check",
      title: "Application security"
    },
    {
      icon: "git-merge",
      title: "AI/ML development"
    },
    {
      icon: "settings",
      title: "ML devops"
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
